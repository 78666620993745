import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

//images
import ACHAuthorizationForm from "../../static/assets/ACH-Authorization-Form.pdf"
import CreditCardForm from "../../static/assets/Recurring-Credit-Card-Form.pdf"
import NPWiredInstructions from "../../static/assets/NP-Wire-Instructions.pdf"

//data
// import { PremiumFinanceData } from "../components/data/data"

const MakeAPayment = () => {
  return (
    <Layout>
      <SEO
        title="Premium Finance - National Partners"
        description="A Dedicated Agent Support Team Once you get started with the application process, you’ll be assigned an Agent Services Manager who is always there to help answer any questions and provide support. National Partners will work together with you to help you achieve your insurance premium finance goals. And our Agent Services team is just […]"
      />
      <div className="page-headline">
        <div
          className="hero-headline payment-page"
          style={{ padding: "10rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="section-heading text-center">
                  <div className="hero-body">
                    <h1 className="hero-page-title mb-4">Make A Payment</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content pb-280 pt-0 payments-wrapper">
        <section className="pb-0">
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-lg-auto">
                  <div className="section-heading text-center">
                    <h6 className="section-subtitle text-gold text-uppercase">
                      payment options
                    </h6>
                    <hr className="section-hr" />
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article>
            <div className="container">
              <div className="row" style={{ marginTop: "5rem" }}>
                <div className="col-lg-10 mx-auto">
                  <h2>Check Payments:</h2>
                  <ul>
                    <li>
                      <b>Online</b> – Access your account by
                      <u>
                        <a
                          href="https://www.nationalpartners.com/clients/"
                          className="mx-1"
                        >
                          clicking here.
                        </a>
                      </u>
                      Please note that when transacting your payment you may
                      submit a one-time payment or a recurring payment.
                    </li>
                    <li className="mt-4">
                      <b>Phone</b> – Call our toll-free number at{" "}
                      <b className="mx-1">800-506-8901</b>
                      and press option <b className="mx-1">“1"</b> to make a
                      secure one-time check payment through our automated phone
                      attendant.
                    </li>
                    <li className="mt-4">
                      <b>Regular Mail</b> – Please mail your payment along with
                      the payment stub provided to the address listed below:
                      <ul>
                        <li>
                          <b>Insureds in all US states and territories:</b> PO
                          Box 89 Denver, CO 80201-0089
                        </li>
                      </ul>
                    </li>
                    <li className="mt-4">
                      <b>Overnight Mail</b> – When overnighting a payment,
                      please send to: 1600 Stout St., Suite 900 Denver, CO 80202
                    </li>
                    {/* <li>Integration with DocuSign</li>
                  <li>
                    Multiple quote options so client may determine best
                    course-of-action for business
                  </li> */}
                  </ul>
                  <p className="mt-5" style={{ marginLeft: "2rem" }}>
                    <i style={{ fontWeight: "300" }}>
                      Electronic check payments are available, with a
                      transaction fee up to $10.00..
                    </i>
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginTop: "7rem" }}>
                <div className="col-lg-10 mx-auto">
                  <h2>Credit Card Payments</h2>
                  <ul>
                    <li>
                      <b>Online</b> – Access your account by
                      <u>
                        <a
                          href="https://www.nationalpartners.com/clients/"
                          className="ml-1"
                        >
                          clicking here
                        </a>
                      </u>
                      . Please note that when transacting your payment you may
                      submit a one-time payment or a recurring payment.
                    </li>
                    <li className="mt-4">
                      <b>Phone</b> – Call our toll-free number at{" "}
                      <b className="mx-1">800-506-8901</b>
                      and press option <b className="mx-1">“1”</b> to make a
                      secure one-time check payment through our automated phone
                      attendant.
                    </li>
                  </ul>
                  <p className="mt-5" style={{ marginLeft: "2rem" }}>
                    <i>
                      We currently accept Visa, MasterCard, Discover and
                      American Express. The convenience fee for this method of
                      payment, charged by our third-party provider, is 3.25% of
                      the transaction amount.
                    </i>
                  </p>
                </div>
              </div>
              <div className="row" style={{ marginTop: "7rem" }}>
                <div className="col-lg-10 mx-auto">
                  <h2>Additional Payment Options:</h2>
                  <ul>
                    <li>
                      <b>Recurring ACH Debit</b> – Complete the Recurring ACH
                      Form and return to our Customer Service Team at
                      <a
                        href="mailto:customerservice@nationalpartners.com"
                        className="ml-1"
                      >
                        customerservice@nationalpartners.com
                      </a>
                      . You may obtain this form by
                      <a
                        href={ACHAuthorizationForm}
                        target="_blank"
                        className="ml-1"
                      >
                        clicking here
                      </a>
                      .
                      <i className="font-italic ml-1">
                        This option is available to you at no additional cost.
                      </i>
                    </li>
                    <li className="mt-4">
                      <b>Recurring Credit Card</b> – Complete the Recurring
                      Credit Card Form and return to our Customer Service Team
                      at
                      <a
                        href="mailto:customerservice@nationalpartners.com"
                        className="ml-1"
                      >
                        customerservice@nationalpartners.com
                      </a>
                      . You may obtain this form by
                      <a href={CreditCardForm} target="_blank" className="ml-1">
                        clicking here
                      </a>
                      .
                      <i className="font-italic ml-1">
                        The convenience fee for this method of payment, charged
                        by our third-party provider, is 3.25% of the transaction
                        amount.
                      </i>
                    </li>
                    <li className="mt-4">
                      <b>Wire / ACH Transfer</b> – You may find our current
                      banking instructions by{" "}
                      <a
                        href={NPWiredInstructions}
                        target="_blank"
                        className="ml-1"
                      >
                        clicking here
                      </a>
                      .
                      <i className="font-italic ml-1">
                        Please note that while we do not charge a fee for this
                        option, your bank may charge an associated transaction
                        fee.
                      </i>
                    </li>
                  </ul>
                  <p className="mt-5" style={{ marginLeft: "2rem" }}>
                    <b>Require additional assistance?</b> Contact our Customer
                    Service team at 800-506-8901 or
                    <a
                      href="mailto:customerservice@nationalpartners.com"
                      className="mx-1"
                    >
                      customerservice@nationalpartners.com
                    </a>
                    and we will be happy to assist you. Thank you for financing
                    with National Partners!
                  </p>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    </Layout>
  )
}

export default MakeAPayment
